.custom-popup {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color:#000000;
}:hover{
     color:#000000;
 }

.custom-popup h3 {
    font-size: 16px;
    margin: 0 0 5px;
    color:#000000;
    font-weight: 100;
}

.custom-popup p {
    margin: 0;
    color:#000000;
}

.marker-hover {
    border: 2px solid #FFFFFF;
}

/* Style the crosshair container */
.crosshair {
    width: 200px;
    height: 200px;
    border: 1px solid #FFFFFF;
    position: relative;
    background-color: #FFFFFF;
  }
  
  /* Define the horizontal and vertical lines of the crosshair */

  
  /* Hide the lines by default */
  .crosshair::before,
  .crosshair::after {
    opacity: 0;
  }
  
  /* Show the lines when hovering over the container */
  .crosshair:hover::before,
  .crosshair:hover::after {
    opacity: 1;
  }

  
  
  
  