::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:  #000000;
    box-shadow: inset 0 0 5px rgba(145, 145, 144, 0.6);
    border-radius: 0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:  #919190;
    border-radius: 3px;
  }

  html {
      height:100%;
      margin:0;
      padding:0;
  }
  body{
      height:100%;
      margin:0;
      padding:0;
  }

  #root{
    margin:0;
    padding:0;
  }
 